import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

function Testimonial({ text, person, organisation }) {
  return (
    <Card>
      <CardContent>
        <Box py={{ sm: 2, md: 5 }}>
          {text.map((textContent, index) => (
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              key={index}
              paragraph={index + 1 === text.length}
              gutterBottom
            >
              {textContent}
            </Typography>
          ))}
          <Typography variant="body1" color="textPrimary" component="p">
            — {person}
            {Boolean(organisation) ? `, ${organisation}` : ""}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default Testimonial

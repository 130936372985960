import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Testimonial from "../components/testimonial"
import Box from "@material-ui/core/Box"
import { contentPaddingY } from "../config"

const testimonials = [
  {
    text: [
      "Coastline Roofing Solutions replaced our guttering & down pipe today .",
      "They are a really great firm. They were friendly, quiet & efficient.",
      "I have passed on their phone number to some friends with my recommendation.",
    ],
    person: "Pat Baird",
    organisation: "Woonona",
  },
  {
    text: [
      "Coastline Roofing Solutions has been providing roofing and guttering repairs for our extensive portfolio of properties during the past two years.",
      "During this time we have always found Jono to be very reliable, knowledgeable in his field and always providing the exceptional customer service that we look for in our contractors. We have only ever received positive feedback from clients with regard to the quality of his work.",
      "We would have no hesitation in recommending Coastline Roofing Solutions for roofing and gutter repairs and maintenance.",
    ],
    person: "Andrew Darby",
    organisation: "Chris Darby Strata",
  },
  {
    text: [
      "Price, reliability, good communication, solutions to problems, only quality products and hard work; this is how I would describe Coastline Roofing.",
      "Coastline Roofing ticks all the boxes.",
    ],
    person: "Philip Tyler",
    organisation: "Tyler Developments",
  },
  {
    text: [
      "I was recommended to use Jono from Coastline Roofing by a client of our office, about 18 months ago. Since this time I have used Jono and his team for roofing repairs, guttering cleaning and roof replacements.",
      "I have no hesitation in recommending them as quality tradespeople for the work they have completed for our clients.",
    ],
    person: "Andrew Heard",
    organisation: "Property Manager at MMJ North",
  },
]

const TestimonialsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "roof-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout title="Testimonials" bgImage={data.bgImage.childImageSharp.fluid}>
      <SEO title="Testimonials" />
      <Box display="flex" flexDirection="column" py={contentPaddingY}>
        {testimonials.map((testimonial, index) => (
          <Box mt={index === 0 ? 0 : 8} key={index}>
            <Testimonial {...testimonial} width={1} />
          </Box>
        ))}
      </Box>
    </Layout>
  )
}

export default TestimonialsPage
